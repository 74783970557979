import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { ScrollService } from '../lib/base/scroll.service';
import { AuthStore, ConfigStore } from 'src/lib/signal-store';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { IllnessScriptService } from 'src/lib/illness-script/illness-script.service';
import { ModalComponent } from './components/modal/modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private illnessScriptService: IllnessScriptService
  ) {
    super();
  }

  authStore = inject(AuthStore);
  configStore = inject(ConfigStore);
  @ViewChild(ModalComponent) modal!: ModalComponent;
  visibleIllness: IllnessScriptModel | null = null;
  applyStyle = false;
  gradient = false;

  showModal() {
    this.modal.showModal();
  }

  ngAfterContentChecked(): void {
    this.visibleIllness && this.showModal();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      'illnessScript' in params &&
        this.illnessScriptService
          .getById(params[`illnessScript`])
          .subscribe((data) => (this.visibleIllness = data));
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe((e) => {
        if (e instanceof NavigationStart) {
          this.applyStyle =
            e.url.includes('step') || e.url.includes('differential');
          this.gradient = e.url === '/' || e.url.includes('/?illnessScript');

          this.scrollService.scrollContentToTop();
        }
      });
  }
}
