import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingStore } from 'src/lib/signal-store';
import { environment } from 'src/environments/environment';
import { finalize, map } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  route = inject(ActivatedRoute);
  loadingStore = inject(LoadingStore);

  exceptions = [
    {
      method: 'GET',
      url: '/clinical-reasoning-middleware',
    },
    {
      method: 'POST',
      url: '/clinical-reasoning-middleware',
    },
  ];

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const mhToken = window.localStorage.getItem('MH_TOKEN');
    const isExempted = this.exceptions.some(
      (e) => e.method === request.method && request.url.indexOf(e.url) > -1
    );
    if (!isExempted) {
      this.loadingStore.toggleLoading(true);
    }
    if (request.withCredentials && mhToken) {
      return next
        .handle(
          request.clone({
            url: `${request.url}`,
            setHeaders: {
              Authorization: `Bearer ${mhToken}`,
            },
          })
        )
        .pipe(
          map((event) => {
            if (event instanceof HttpResponse) {
              const authHeaders = event.headers.getAll('Authorization') || [];
              authHeaders.forEach((token) => {
                const newToken = token.split(' ')[1];
                if (newToken) {
                  window.localStorage.setItem('MH_TOKEN', newToken);
                }
              });
            }
            return event;
          }),
          finalize(() => {
            this.loadingStore.toggleLoading(false);
          })
        );
    } else if (request.url.startsWith('/api')) {
      return next
        .handle(
          request.clone({
            url: `${request.url}`,
          })
        )
        .pipe(
          finalize(() => {
            this.loadingStore.toggleLoading(false);
          })
        );
    } else {
      return next
        .handle(
          request.clone({
            url: request.url,
          })
        )
        .pipe(
          finalize(() => {
            if (!isExempted) {
              this.loadingStore.toggleLoading(false);
            }
          })
        );
    }
  }
}
