import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ScreenState {
  zoomLevel: number;
  isHighZoom: boolean;
  isVeryHighZoom: boolean;
  screenWidth: number;
  screenHeight: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private screenState = new BehaviorSubject<ScreenState>(
    this.getInitialState()
  );

  constructor() {
    // Listen for both resize and scroll events as some browsers trigger different events
    window.addEventListener('resize', () => this.checkScreenState());
    window.addEventListener('scroll', () => this.checkScreenState());

    // Check state on visibility change (for browsers that don't trigger resize)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkScreenState();
      }
    });
  }

  detectZoomLevel(): number {
    // Method 1: Using window.devicePixelRatio (Chrome, Firefox, Safari)
    if (window.devicePixelRatio) {
      return Math.round(window.devicePixelRatio * 100);
    }

    // Method 2: Using screen.logicalXDPI (IE)
    if (
      (screen as any).logicalXDPI !== undefined &&
      (screen as any).systemXDPI !== undefined
    ) {
      return Math.round(
        ((screen as any).systemXDPI / (screen as any).logicalXDPI) * 100
      );
    }

    // Method 3: Using window.outerWidth (fallback)
    if (window.outerWidth && window.innerWidth) {
      const zoomLevel = Math.round(
        (window.outerWidth / window.innerWidth) * 100
      );

      // Validate the zoom level (some browsers might return incorrect values)
      if (zoomLevel >= 25 && zoomLevel <= 500) {
        // reasonable zoom range
        return zoomLevel;
      }
    }

    // Method 4: Using visual viewport (modern browsers)
    if (window.visualViewport) {
      return Math.round(window.visualViewport.scale * 100);
    }

    // Default fallback
    return 100;
  }

  private getInitialState(): ScreenState {
    const zoomLevel = this.detectZoomLevel();
    return {
      zoomLevel,
      isHighZoom: zoomLevel >= 300 && zoomLevel < 400,
      isVeryHighZoom: zoomLevel >= 400,
      screenWidth: this.getActualWidth(),
      screenHeight: this.getActualHeight(),
    };
  }

  private getActualWidth(): number {
    // Use visual viewport if available
    if (window.visualViewport) {
      return window.visualViewport.width;
    }

    // Fallback to innerWidth
    return window.innerWidth;
  }

  private getActualHeight(): number {
    // Use visual viewport if available
    if (window.visualViewport) {
      return window.visualViewport.height;
    }

    // Fallback to innerHeight
    return window.innerHeight;
  }

  checkScreenState() {
    // Debounce the check to avoid multiple rapid updates
    if (this.checkTimeout) {
      window.clearTimeout(this.checkTimeout);
    }

    this.checkTimeout = window.setTimeout(() => {
      const newState = this.getInitialState();
      if (JSON.stringify(newState) !== JSON.stringify(this.screenState.value)) {
        this.screenState.next(newState);
      }
    }, 100); // 100ms debounce
  }

  private checkTimeout: number | null = null;

  getScreenState(): Observable<ScreenState> {
    return this.screenState.asObservable();
  }

  // Helper method to force a state check
  public forceCheck(): void {
    this.checkScreenState();
  }

  // Cleanup method
  public destroy(): void {
    if (this.checkTimeout) {
      window.clearTimeout(this.checkTimeout);
    }
    window.removeEventListener('resize', () => this.checkScreenState());
    window.removeEventListener('scroll', () => this.checkScreenState());
    document.removeEventListener('visibilitychange', () =>
      this.checkScreenState()
    );
  }
}
