import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  constructor(private readonly httpService: HttpClient) {}

  getCookies(
    packageId: string,
    productId = 'clinical-reasoning',
    locale = 'en',
    version = 1
  ) {
    return this.httpService
      .post<CookieResponse>(
        environment.umeApiBaseUrl + '/authorize',
        this.cdnTokenQuery(packageId, productId, locale, version),
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .pipe(map(({ data }) => data.cdnCookies.cookies));
  }

  private cdnTokenQuery(
    packageId: string,
    productId: string,
    locale: string,
    version: number
  ) {
    const query = `query CdnToken($productId: String!, $packageId: String!, $locale: String!, $version: Int!) {
      cdnCookies(
          productId: $productId
          packageId: $packageId
          locale: $locale
          version: $version
      ) {
          ok
          cookies {
              key
              value
          }
      }
  }`;
    return JSON.stringify({
      query,
      variables: { packageId, productId, locale, version },
    });
  }
}

export interface CookieResponse {
  data: {
    cdnCookies: {
      ok: boolean;
      cookies: Cookie[];
    };
  };
}

export interface Cookie {
  key: string;
  value: string;
}
