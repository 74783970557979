import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiInterceptor } from './http-interceptors/api-interceptor';
import { HeaderService } from 'src/lib/header/header.service';
import { HeaderWrapperModule } from './components/header-wrapper/header-wrapper.module';
import { ScrollService } from '../lib/base/scroll.service';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthService } from '../lib/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ResponseInterceptor } from './http-interceptors/response.interceptor';
import { WorkingDDxTableModule } from './components/working-ddx-table/working-ddx-table.module';
import { ModalComponent } from './components/modal/modal.component';
import { SingleIllnessComponent } from './components/single-illness/single-illness.component';
import { NotFoundComponent } from './not-found/not-found.component';
import ButtonComponent from './components/button/button.component';
import { SvgComponent } from './components/svg/svg.component';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects } from 'src/lib/router/store/router.effect';
import { routerFeatureKey } from 'src/lib/router/store/router.selector';
import { routerCustomReducer } from 'src/lib/router/store/router.reducer';

@NgModule({
  declarations: [AppComponent, RedirectComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ModalComponent,
    SingleIllnessComponent,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: isDevMode(), // Restrict extension to log-only mode
    }),
    HeaderWrapperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    WorkingDDxTableModule,
    ButtonComponent,
    SvgComponent,
    StoreModule.forRoot({
      [routerFeatureKey]: routerCustomReducer,
    }),
    EffectsModule.forRoot([RouterEffects]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
  ],
  exports: [StoreModule, EffectsModule, StoreRouterConnectingModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    HeaderService,
    ScrollService,
    CookieService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
