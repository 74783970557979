import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { inject } from '@angular/core';
import { take, tap } from 'rxjs';
// import { withDevtools } from '@angular-architects/ngrx-devtools';
import { HttpClient } from '@angular/common/http';

export interface User {
  id: string;
  username: string;
  email: string;
  givenName: string;
  familyName: string;
  middleName?: string;
  locale: string;
  timezone: string;
  profilePictureUrl: string;
}

export interface AuthenticationState {
  user: User;
}

export interface AuthenticationResponse {
  data: {
    getUser: {
      user: User;
    };
  };
}

export const AuthStore = signalStore(
  { providedIn: 'root' },
  // withDevtools({ name: 'AuthStore' }),
  withState<AuthenticationState>({
    user: {
      id: '',
      username: '',
      email: '',
      givenName: '',
      familyName: '',
      locale: '',
      timezone: '',
      profilePictureUrl: '',
    },
  }),
  withMethods((store, http = inject(HttpClient)) => ({
    getCurrentUser(): void {
      http
        .post<AuthenticationResponse>('/users', buildQuery(), {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          take(1),
          tap((response) => {
            const user = response.data.getUser.user;
            patchState(store, (state) => ({ ...state, user }));
          })
        )
        .subscribe();
    },
  })),
  withHooks({
    onInit(store) {
      store.getCurrentUser();
    },
  })
);

function buildQuery(): string {
  const query = `
    query GetUser {
      getUser {
        user {
          id,
          givenName,
        }
      }
    }
  `;

  const formattedQuery = JSON.stringify({
    query: query,
  });
  return formattedQuery;
}
