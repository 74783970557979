const mhEnv = 'dev';
export const environment = {
  apiBaseUrl:
    'https://api-dev.cr.nonprod.mheducation.com/clinical-reasoning-middleware',
  umeApiBaseUrl: `https://api-${mhEnv}.ume.nonprod.mheducation.com`,
  fileBaseUrl: 'assets/data',
  env: mhEnv,
  featureFlags: {
    searchComponent: true,
    clinicalReasoningJumpTo: true,
  },
  cookieTtl: 1000 * 60 * 15,
};
