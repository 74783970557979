import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface LoadingState {
  isLoading: boolean;
}

export const LoadingStore = signalStore(
  { providedIn: 'root' },
  withState<LoadingState>({
    isLoading: false,
  }),
  withMethods((store) => ({
    toggleLoading(b: boolean): void {
      patchState(store, (state) => ({ ...state, isLoading: b }));
    },
  }))
);
